<template>
  <b-container fluid>
       <b-row>
            <template>
                <b-row>
                    <b-col md="12">
                        <table class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 20%">{{ $t('tcbconfiguration.reason_en')}}</th>
                                <td style="width: 30%">{{ items.reason_en }} </td>
                                <th style="width: 20%">{{ $t('tcbconfiguration.reason_bn')}}</th>
                                <td style="width: 30%">{{ items.reason_bn }} </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
        </b-row>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
export default {
    name: 'Details',
    props: ['id'],
    components: {
    },
    created () {
      const tmp = this.getData()
      this.items = tmp
    },
    data () {
        return {
            licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
            items: {}
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getData () {
            const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
            return JSON.parse(JSON.stringify(tmpData))
        }
    }
}

</script>
